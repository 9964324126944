
import React from 'react';
  
const Footer = () => (
  <footer className="footer">
    <p> <span>Disclaimer</span> |This Website is not an official site and it belongs to the authorised channel partner for information purpose only.</p>
  
  </footer>
);
  
export default Footer;