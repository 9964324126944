import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import {useNavigate} from 'react-router-dom';

const Contact = () => {
  const navigate=useNavigate();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_hke3oqd', 'template_e2jdiml', form.current, '7XzuGFqC7w-jNN4J3')
      .then((result) => {
          console.log(result.text);
          navigate("/Thankyou")
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div className='contact'>
        
        <div className='contact1'>
            <div><img src="https://i.imgur.com/T9PgNSc.png" style={{width:"90%",margin:"15px"}}/></div>
        </div>
        <div className='contact2'>
        <div className='heading'><h2>Get in Touch</h2></div>
        <form ref={form} onSubmit={sendEmail}>
            <div><input type="text" name="user_name" className='input1'  required  placeholder='Enter Your Name'/></div>
            <div><input type="text"  name="contact_number" className='input1'  required  pattern="[1-9]{1}[0-9]{9}" placeholder='Enter Your Name'/></div>
            <div><input type='email' name="user_email" className='input1'  required  placeholder='Enter Your Name'/></div>
            
            <div style={{alignItems:'center'}}><button className='btn'>Contact Us</button></div>
            </form>
        </div>
    </div>
  )
}

export default Contact;