import React, { useState } from "react"
import "./navbar.css"


const Navbar = () => {

  return (
    <>
      <nav className='navbar'>
        <div style={{marginLeft:"15px"}}><img src="https://i.imgur.com/NQ6yeL6.png" style={{height:"70px"}}/></div>
        <div >
  <div><h2 style={{marginLeft:"15px",color:"white",fontSize:"12px",textAlign:"center"}}>For Any query Contact Us</h2></div>
  <a href="tel:8130306040"><button className="neonbtn" style={{zIndex:'100',marginLeft:"33px",marginBottom:"4px"}}><h5 className="neon" style={{zIndex:'110',position:'relative'}}>+91-81-3030-6040</h5></button></a>
</div>
       
      </nav>
    </>
  )
}
export default Navbar;