import logo from './logo.svg';
import Navbar from './Navbar';
import Body from './Body';
import Footer from './Footer';
import Sidebar from './Sidebar';
import Contact from './Contact';
function App() {
  return (
    <div className="App">
    <Navbar/>
    <Body/>
    <Contact/>
    <Footer/>
    </div>
  );
}

export default App;
