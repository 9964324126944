import React, { useState } from "react";
import './style.css';
function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="sidebar-container">
      <button
        className="sidebar-toggle"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? "Close" : "Open"} Sidebar
      </button>
      <nav className={`sidebar ${isOpen ? "open" : "closed"}`}>
        <ul>
          <li>Home</li>
          <li>About</li>
          <li>Contact</li>
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;