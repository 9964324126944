import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import {useNavigate} from 'react-router-dom';
import "./style.css"
const Body = () => {

  const navigate=useNavigate();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_hke3oqd', 'template_e2jdiml', form.current, '7XzuGFqC7w-jNN4J3')
      .then((result) => {
          console.log(result.text);
          navigate("/Thankyou")
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div style={{display:"flex"}}>
    <div className='wrapper' >
      <div className='choose'>Choose What Suits You</div>
        <div className='block1'>
            <div ><img src="https://i.imgur.com/yWxcjX7.jpg" className='imglogo' /></div>
            <div><img src="https://i.imgur.com/kqVLf3f.jpg" className='imglist'/></div>
            <p style={{textAlign:"center"}}>GH-15, Eco City, Sector-75, Noida, Gautam Buddha Nagar<br/> Uttar Pradesh-201307<br/> Ongoing | Price:&nbsp;1.65 Cr Onwards </p>
            <div style={{marginTop:"35px"}}><a className='button' href="https://ivy.abacountygroup.com" target="_blank">Visit Website &nbsp;<i class="fas fa-external-link-alt"></i></a></div>
        </div>
        <div className='block2'>
        <div><img src="https://i.imgur.com/ulQTntN.jpg" className='imglogo' style={{height:"80px"}} /></div>
            <div><img src="https://i.imgur.com/lvFo5Ap.jpg" className='imglist' /></div>
            <p style={{textAlign:"center"}}>GH-3C, Sector-10, Greater Noida, Dist-Gautam Budh Nagar<br/>Uttar Pradesh-201307<br/> Ongoing | Price:&nbsp; 65.54 Lacs Onwards </p>
            <div  style={{marginTop:"35px"}}><a className='button' href="http://county107.abacountygroup.com/" target="_blank">Visit Website &nbsp;<i class="fas fa-external-link-alt"></i></a></div>
        </div>
        <div className='block3'>
        <div><img src="https://i.imgur.com/6qR20Hn.jpg" className='imglogo'  /></div>
            <div><img src="https://i.imgur.com/IswGZKr.jpg" className='imglist' /></div>
            <p style={{textAlign:"center"}}>GH-15, Eco City, Sector-75, Noida, Gautam Buddha Nagar<br/> Uttar Pradesh-201307<br/> Ongoing | Price:&nbsp;4.51  Cr Onwards </p>
            <div  style={{marginTop:"35px"}}><a className='button' href="http://county107.abacountygroup.com/" target="_blank">Visit Website &nbsp;<i class="fas fa-external-link-alt"></i></a></div>
        </div>
        
        
    </div>
    <div className='sidebar'>
    <form ref={form} onSubmit={sendEmail}>
        <div className='contact2'>
        <div className='heading'><h2>Get in Touch</h2></div>
            <div><input type="text" name="user_name" className='input1' required placeholder='Enter Your Name'/></div>
            <div><input type="text"  name="contact_number" className='input1' required pattern="[1-9]{1}[0-9]{9}" placeholder='Enter Your Contact Number'/></div>
            <div><input type='email' name="user_email" className='input1' required placeholder='Enter Your Email-id'/></div>
            
            <div style={{alignItems:'center'}}><button className='btn'>Contact Us</button></div>
            
        </div>
        </form>
    </div>
    </div>
    
  )
}

export default Body;
